import React, { useEffect, useState } from 'react';
import './stimson.css'
import Userfront, { SignupForm, LoginForm, PasswordResetForm, LogoutButton } from "@userfront/toolkit/react";


const Header = ({userEmail, setUserEmail, setHideArtistSearch, setHideLogin, hideLogin, setHideSignup, hideSignup, setHideLogout, hideLogout, setHidePasswordReset, hidePasswordReset}) => {
  const [hideLoginButton,setHideLoginButton] = useState(userEmail!=undefined)
  const [hideSignupButton, setHideSignupButton] = useState(userEmail!=undefined)
  const [hideLogoutButton,setHideLogoutButton] = useState(userEmail==undefined)

  const openSubscribeLink = () => {
    console.log("Clicked")
    window.open('https://checkout.stripe.com/c/pay/cs_live_a1qwyvXuypTzyQLDkCmNr8NCoHanw5nThMHqPzvixI38YlVNtpAAS3uJZ0#fidkdWxOYHwnPyd1blppbHNgWjA0SjY3R21JZEhPMFcyRm98bXVScl93YWNrSlQ1U19iMkBkNktpQl9KXG1oX001NVNHazxyS1FVRE1DNVdjdWNJNzNUazdcZ0BySkFjNG9WcX1oT253SVNVNTU3fH08al81VycpJ3VpbGtuQH11anZgYUxhJz8nMG5EZ05xMjdLN0J%2FZnFSZEBAJyknd2BjYHd3YHdKd2xibGsnPydtcXF1dj8qKm92K3Zxd2x1YCtmamgqJyknaWpmZGlgJz8nYGsneCUl', '_blank');
  };
  
  const setHides = (value) => {
    console.log(typeof setHideLogin, userEmail)
    if (value === 'login') {
      setHideLogin(false)
      setHideSignup(true)
    }

    if (value==='signup') {
      setHideSignup(false)
      setHideLogin(true)
    }
    if (value === 'logout') {
      setHideSignup(true)
      setHideLogin(true)
      setHideArtistSearch(true)
    }
  }

  // 3. Log out the user
  function logout(e) {
    // Prevent the form's default behavior
    e.preventDefault();
    // Call Userfront.logout()
    Userfront.logout();
  }

  return (
    <div className="navbar">
      <div>
      <h1 className="h1" style={{color:"white", fontWeight:"bold"}}>Chartist</h1>
      </div>
      <div>
      <button className="btn" onClick={() => openSubscribeLink()}>Subscribe</button>
      <button hidden={hideLoginButton} className='btn' style={{"marginLeft":"10px"}} onClick={() => setHides('login')}>Log in</button>
      <button hidden={hideSignupButton} className='btn' style={{"marginLeft":"10px"}} onClick={() => setHides('signup')}>Sign up</button>
      <button hidden={hideLogoutButton} className='btn' style={{"marginLeft":"10px"}} onClick={logout}>Log out</button>
      </div>
      </div>
  )
};

export default Header;