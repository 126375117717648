import React, { useState } from 'react';

const Tabs = ({ tabsData, activeTab, setActiveTab }) => {

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs-container">
      <div className="tab-buttons">
        {tabsData.map((tab, index) => (
          <tabButton
            key={index}
            onClick={() => handleTabClick(index)}
            className={index === activeTab ? 'active' : ''}
          >
            {tab.title}
          </tabButton>
        ))}
      </div>
      <div className="tab-content">
        {tabsData[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;
