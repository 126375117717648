import React, { useEffect, useState } from 'react';
import {MDBDataTableV5} from 'mdbreact'

const ChartPopup = ({hidden, keys,country,displayData, setHideChartPopup}) => {
  try {
    return (
      <div hidden={hidden} style={{"width":"80%","marginLeft":"10%"}}>
        <div className="stims_row" style={{"justifyContent":"space-between"}}>
          <h1 style={{"width":"99%"}}>{country}</h1>
          <h1 onClick={(() => setHideChartPopup(true))}>x</h1>
          </div>
          <MDBDataTableV5 hover entriesOptions={[5, 15, 25,50,100,200]} entries={15} pagesAmount={4} data={
            {columns:
              keys.map(key => ({label: key, field: key })),
              rows:displayData.rows.filter(item => item.Country === country)}
            } searching={true}/>
          </div>
    )
  }
  catch {
    return (<div></div>)
  }
};

export default ChartPopup;