import React, { useEffect, useState } from 'react';
import {MDBDataTableV5} from 'mdbreact'

const DataTable = ({getData,keys,artistSearch,spreadsheetID,sheetName, type, direction_sort}) => {
  const [originalData,setOriginalData] = useState([])
  const [displayData,setDisplayData] = useState({})
  
  const sortData = (data, key) => { //direction is 'asc' or 'desc'
    const sortedData = [...data]; // Create a copy of the array to avoid mutating the state
    if (direction_sort == 'asc') {
    sortedData.sort((a, b) => a[key] - b[key]); // Sort the array based on the specified key
  }
  else {
    sortedData.sort((b, a) => a[key] - b[key]);
  }
    
    return sortedData
  };

  useEffect(() => {
    getData(spreadsheetID, sheetName, keys)
      .then((data) => {
        setOriginalData(Object.values(data).filter(item => item.Artist && item.Artist !== ''))
      setDisplayData({columns:
    keys.map(key => ({label: key, field: key })),
    rows:Object.values(data)})
      })
      .catch((error) => {
        console.log('err',error)
        // Handle the error if necessary
      });
  },[]);

  useEffect(() => {
    var data = originalData.filter(item => item.Artist.includes(artistSearch))

    if (type=="youtube") {
      data = sortData(data,"Views")
      data = data.map((item, index) => ({
        ...item,
        Views: parseInt(item.Views).toLocaleString(),
      }));
    }

    if (type=="charts") {
      data = sortData(data,"Position")
    }

    setDisplayData({columns:
    keys.map(key => ({label: key, field: key })),
    rows:data})
  },[artistSearch])

  return (
      <MDBDataTableV5 hover entriesOptions={[5, 15, 25,50,100,200]} entries={15} pagesAmount={4} data={displayData} searching={false}/>
  );
};

export default DataTable;