import React, { useEffect, useState } from 'react';
import {MDBDataTableV5} from 'mdbreact'

const ChartsTab = ({hidden,activeTab,setChartPopupCountry,uniqueChartCountries,setHideChartPopup}) => {
  const [uniqueCountries,setUniqueCountries] = useState([]);

  const [visiblePopup, setVisiblePopup] = useState([]);
  const [visibleCountryPopup,setVisibleCountryPopup] = useState('')
  const [hideCountryList, setHideCountryList] = useState(false);

  const toggleElement = (country) => {
    if (visibleCountryPopup === country) {
      // If the element is already visible, hide it
      setVisiblePopup([]);
      setVisibleCountryPopup('')
    } else {
      setChartPopupCountry(country)
      setHideChartPopup(false)
    }
  };

  

  return (
    <div hidden={hidden}>
      <div style={{"position":"absolute","backgroundColor":"#777777","height":"100%"}}>{visiblePopup}</div>
    <div style={{"textAlign":"left"}}>
    {uniqueChartCountries.map(country => (
      <div style={{"display":"flex","flexDirection":"row","width":"80%"}}>
      <h1 style={{"width":"25%"}} key={country}>{country}</h1>
      <chartButton style={{"width":"25%"}} onClick={() => toggleElement(country)}>Apple Music</chartButton>
      <chartButton style={{"width":"25%"}}>Spotify</chartButton>
      <chartButton style={{"width":"25%"}}>Shazam</chartButton>
      </div>   
    ))}
    </div>
  </div>
      );
};

export default ChartsTab;