import React, { useEffect, useState } from 'react';
import {MDBDataTableV5} from 'mdbreact'

const GeneralStats = ({hideArtistSearch,getData,keys,artistSearch,spreadsheetID,sheetName}) => {
  const [originalData,setOriginalData] = useState([])
  const [displayData,setDisplayData] = useState({})
  const [spotifyFollowers,setSpotifyFollowers] = useState('-')
  const [spotifyMonthlyListeners, setSpotifyMonthlyListeners] = useState('-')
  const [spotifyPopularity, setSpotifyPopularity] = useState('-')
  const [youtubeSubscribers, setYoutubeSubscribers] = useState('-')
  
  useEffect(() => {
    getData(spreadsheetID, sheetName, keys)
      .then((data) => {
        setOriginalData(Object.values(data).filter(item => item.Artist && item.Artist !== ''))
      setDisplayData({columns:
    keys.map(key => ({label: key, field: key })),
    rows:Object.values(data)})
      })
      .catch((error) => {
        console.log('err',error)
        // Handle the error if necessary
      });
  },[]);

  useEffect(() => {
    var data = originalData.filter(item => item.Artist.includes(artistSearch))
      setDisplayData({columns:
    keys.map(key => ({label: key, field: key })),
    rows:data})
    try {
      setSpotifyFollowers(data[0]["Spotify Followers"])
      setSpotifyMonthlyListeners(data[0]["Spotify Monthly Listeners"])
      setSpotifyPopularity(data[0]["Spotify Popularity"])
      setYoutubeSubscribers(data[0]["Youtube Subscribers"])
    }
    catch {}
  },[artistSearch])

  return (
    <div className="stims_row" hidden={hideArtistSearch} style={{"marginLeft":"10%","marginRight":"10%","justifyContent":"space-between"}}>
    <div>
      <h2>Spotify Followers</h2>
      <h1>{spotifyFollowers}</h1>
    </div>
    <div>
      <h2>Spotify Monthly Listeners</h2>
      <h1>{spotifyMonthlyListeners}</h1>
    </div>
    <div>
      <h2>Spotify Popularity</h2>
      <h1>{spotifyPopularity}</h1>
    </div>
    <div>
      <h2>Youtube Subscribers</h2>
      <h1>{parseInt(youtubeSubscribers).toLocaleString()}</h1>
    </div>
    </div>
  );
};

export default GeneralStats;