// To deploy in gcloud: cd into app_react_sub, gcloud app_deploy

import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './stimson.css'
import axios from 'axios';
import Header from './Header.js'
import DataTable from './DataTable.js'
import GeneralStats from './GeneralStats.js'
import ChartsTab from './ChartsTab.js'
import ChartPopup from './ChartPopup.js';
import Tabs from './Tabs.jsx';
import StripeBuyButton from './StripeBuyButton';
import Userfront, { SignupForm, LoginForm, PasswordResetForm, LogoutButton } from "@userfront/toolkit/react";
import Cookies from 'universal-cookie';
import Stripe from 'stripe';

const stripe = new Stripe('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');

// import CheckoutForm from "./CheckoutForm";

function getData(spreadsheet_id, sheet_name, keys) {
  var API_KEY = 'AIzaSyBEhh9WEMThVwTJ4Q2E7w7BcZa-GQwjdtM';
  var apiUrl = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheet_id}/values/${sheet_name}?key=${API_KEY}`;

  https://sheets.googleapis.com/v4/spreadsheets/1sjBbMDxEMwR26bPNl2-6BrPcSoDxynEaoskg-F7pRQg/values/views?key=AIzaSyBEhh9WEMThVwTJ4Q2E7w7BcZa-GQwjdtM

  return axios.get(apiUrl)
    .then((response) => {
      var data = response.data.values;
    
      const transformedObject = {};

      for (let i = 1; i < data.length; i++) {
        const values = data[i];
        const obj = {};

        for (let j = 0; j < keys.length; j++) {
          obj[keys[j]] = values[j];
        }

        transformedObject[i - 1] = obj;
      }

      data = Object.keys(transformedObject).reduce((acc, key) => {
        const originalItem = transformedObject[key];
        const newItem = {};

        keys.forEach((keyToKeep) => {
          if (originalItem.hasOwnProperty(keyToKeep)) {
            newItem[keyToKeep] = originalItem[keyToKeep];
          }
        });

        acc[key] = newItem;
        return acc;
      }, {});

      return data;
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      throw error; // Rethrow the error to handle it outside this function if needed
    });
}




function App() {
  var generalKeys = ["Artist",	"Date","Spotify Followers",	"Spotify Monthly Listeners",	"Spotify Popularity","Youtube Subscribers",]
  const chartKeys = ["Artist", "Date","Chart",	"Country",	"City",	"Position",	"Track"];
  const youtubeKeys = ["Artist",	"Date",	"Video",	"Views"]
  const [artistInput, setArtistInput] = useState('')
  const [artistSearch, setArtistSearch] = useState('');
  const [hideData, setHideData] = useState(true)
  const [activeTab, setActiveTab] = useState(0);

  const [originalChartData,setOriginalChartData] = useState([])
  const [displayChartData,setDisplayChartData] = useState({})
  const [uniqueChartCountries,setUniqueChartCountries] = useState([]);

  const [chartPopupCountry, setChartPopupCountry] = useState('');
  const [hideChartPopup, setHideChartPopup] = useState(true)
  const [hideChartCountryList, setHideCountryList] = useState(false)

  const [hideArtistSearch, setHideArtistSearch] = useState(true)

  const [hideSignup, setHideSignup] = useState(true)
  const [hideLogin, setHideLogin] = useState(false)
  const [hideLogout, setHideLogout] = useState(true)
  const [hidePasswordReset, setHidePasswordReset] = useState(true)

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const customerList = await stripe.customers.list();
        setCustomers(customerList.data);
        console.log('customer',customerList.data)
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    fetchCustomers();
  }, []);

  Userfront.init("zn5z7v5b");

  const [userEmail, setUserEmail] = useState(Userfront.user.email)

  console.log('userEmail',userEmail)
  
  function hasEmailinDatabase(data, searchTerm) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key].email.includes(searchTerm)) {
        return true; // 'searchTerm' found in an 'email' value
      }
    }
    return false; // 'searchTerm' not found in any 'email' value
  }
  
  const isSubscriber = hasEmailinDatabase(customers, userEmail);
  console.log('issub?',isSubscriber)

  const sortData = (data, key, direction) => { //direction is 'asc' or 'desc'
    const sortedData = [...data]; // Create a copy of the array to avoid mutating the state
    if (direction == 'asc') {
    sortedData.sort((a, b) => a[key] - b[key]); // Sort the array based on the specified key
  }
  else {
    sortedData.sort((b, a) => a[key] - b[key]);
  }
}


  useEffect(() => {
    getData("1b4X6ffi8s1aOY1rN-nxgA_JQkxEIctA-vlyYybkcjHM", "Charts",chartKeys)
      .then((data) => {
        setOriginalChartData(sortData(Object.values(data).filter(item => item.Artist && item.Artist !== ''),"Position","asc"))
      setDisplayChartData({columns:
    chartKeys.map(key => ({label: key, field: key })),
    rows:Object.values(data)})
    setUniqueChartCountries([...new Set(Object.values(data).map(item => item.Country))].sort())
      })
      .catch((error) => {
        console.log('err',error)
        // Handle the error if necessary
      });
  },[]);

    // Function to handle the input change
    const handleInputChange = (e) => {
      const newValue = e.target.value;
      setArtistInput(newValue);
      console.log('Input value changed:', newValue);
    };
  
    const handleSearchSubmit = (e) => {
      if (artistInput != '') {
      setArtistSearch(artistInput);
      setHideData(false)
      }
    };

  const tabsData = [
    {
      title: 'Artist Search',
      content: 
      <div className='interior-body'>
      <div hidden={!isSubscriber} className="stims_row" style={{"marginTop":"20px","justifyContent":"center"}}>
      <input value={artistInput}
        className="large-open-input"
        onChange={handleInputChange}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            handleSearchSubmit()
          }
        }}
        onSubmit={handleSearchSubmit}
        placeholder="Search artists..."></input>
        <button className="search-button" onClick={handleSearchSubmit}>
      <i className="fa fa-search fa-2x"></i>
    </button>
        </div>
        <div hidden={isSubscriber}>
        <div hidden={hideSignup}>
        <SignupForm></SignupForm>
        </div>
        <div hidden={hideLogin}>
        <LoginForm></LoginForm>
        <div style={{"height":"20px"}}></div>
        <button className="btn" onClick={() => setHidePasswordReset(false)}>Reset password</button>
        <div style={{"height":"20px"}}></div>
        <div hidden={hidePasswordReset}>
        <PasswordResetForm></PasswordResetForm>
        </div>
        </div>
        </div>
        <div hidden={!isSubscriber}>
        <GeneralStats hidden={hideArtistSearch} getData={getData} keys={generalKeys} artistSearch={artistSearch} spreadsheetID={"1lSXUq2974V8DzYDIIj7-gkjvdMQikI6G1pz-zQ4BbGc"} sheetName={"General"} type="general" direction_sort="desc"/>
     <h1>Charts</h1>
      <DataTable getData={getData} keys={chartKeys}  artistSearch={artistSearch} spreadsheetID={"1b4X6ffi8s1aOY1rN-nxgA_JQkxEIctA-vlyYybkcjHM"} sheetName={"Charts"} type="charts" direction_sort="asc"/>
      <h1>YouTube Views</h1>
      <DataTable getData={getData} keys={youtubeKeys}  artistSearch={artistSearch} spreadsheetID={"1sjBbMDxEMwR26bPNl2-6BrPcSoDxynEaoskg-F7pRQg"} sheetName={"Views"} type="youtube" direction_sort="desc"/>
      </div>
      </div>
    },
    {
      title: 'Charts',
      content: 
      <div>
        <ChartPopup hidden={hideChartPopup} keys={chartKeys} country={chartPopupCountry} displayData={displayChartData} setHideChartPopup={setHideChartPopup}/>
        <div className='interior-body'>
        <ChartsTab hidden={!hideChartPopup} activeTab={activeTab} setChartPopupCountry={setChartPopupCountry} uniqueChartCountries={uniqueChartCountries} setHideChartPopup={setHideChartPopup}/>
        </div>
      </div>
    },
  ];



  return (
    <html>
      <body>
        <div className="App">
        <Header userEmail={userEmail} setUserEmail={setUserEmail} setHideArtistSearch={setHideArtistSearch} setHideLogin={setHideLogin} hideLogin={hideLogin} setHideSignup={setHideSignup} hideSignup={hideSignup} setHideLogout={setHideLogout} hideLogout={hideLogout} setHidePasswordReset={setHidePasswordReset} hidePasswordReset={hidePasswordReset}/>
        <Tabs tabsData={tabsData} activeTab={activeTab} setActiveTab={setActiveTab} />
        <div>
    </div>
        </div>
      </body>
    </html>
  );
}

export default App;